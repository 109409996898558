import React from "react"
import { graphql, Link } from "gatsby"
import get from "lodash/get"
import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

class Blog extends React.Component {
  render() {
    const posts = get(this, "props.data.allContentfulBlogPost.edges")

    const data = get(this, "props.data.allFile.edges")

    return (
      <Layout>
        <SEO title="Blog" />

        <BlogHeroContainer>
          <BlogHeroImage>
            <BlogHeaderImage
              key="2"
              image={data[0].node.childImageSharp.gatsbyImageData}
              alt={data[0].node.name}
            />
          </BlogHeroImage>
          <BlogHeroContent>
            <BlogHeroItems>
              <BlogHeroH1>Blog</BlogHeroH1>
              <BlogHeroP>über Technik, Alpinismus und das Leben</BlogHeroP>
            </BlogHeroItems>
          </BlogHeroContent>
        </BlogHeroContainer>

        <CardContainer>
          {posts.map(({ node }) => {
            return (
              <Card key={node.slug} to={`/blog/${node.slug}`}>
                <CardImageWrapper data-category={node.category}>
                  <CardImage
                    image={node.titleImage.gatsbyImageData}
                    alt={node.title}
                  ></CardImage>
                </CardImageWrapper>
                <CardInfo>
                  <CardText>{node.title}</CardText>
                </CardInfo>
              </Card>
              /*  <Card
                key={`CardItem${node.slug}`}
                fluid={node.titleImage.fluid}
                path={`/blog/${node.slug}`}
                text={node.title}
                label="Adventure"
              ></Card> */
            )
          })}
        </CardContainer>
      </Layout>
    )
  }
}

export default Blog

const BlogHeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: 100vh;
`
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  justify-items: center;
  /*  padding: 2rem 2rem; */
  padding: 5rem calc((100vw - 1200px) / 2);

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
  }

  @media screen and (max-width: 868px) {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
`

const Card = styled(Link)`
  line-height: 2;
  width: 100%;
  /* height: 500px; */
  position: relative;
  border-radius: 10px;
  transition: 0.2 ease;

  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
`

const CardImageWrapper = styled.figure`
  position: relative;
  width: 100%;
  /*  padding-top: 67%; */
  overflow: hidden;
  &:after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
`

const CardImage = styled(GatsbyImage)`
  height: 100%;
  max-width: 100%;
  position: relative;
  /*  border-radius: 10px; */
  filter: brightness(90%);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    /*  filter: brightness(100%); */
    transform: scale(1.1);
  }
`
const CardInfo = styled.div`
  padding: 20px 30px 30px;
`
const CardText = styled.div`
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
`

const BlogHeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 1rem;
  position: relative;
  margin-top: -80px;
  color: #fff;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  }
`

const BlogHeroImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const BlogHeroContent = styled.div`
  z-index: 3;
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1300px) / 2);
`
const BlogHeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  max-height: 100%;
  padding: 0;
  color: #fff;
  line-height: 1.1;
  font-weight: bold;
`
const BlogHeroH1 = styled.div`
  font-size: clamp(3rem, 6vw, 5rem);
  margin-bottom: 1.5rem;
  // letter-spacing: 3px;
  padding: 0 1rem;
`
const BlogHeroP = styled.div`
  font-size: clamp(1rem, 3vw, 3rem);
  margin-bottom: 2rem;
`

export const pageQuery = graphql`
  query BlogQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(filter: { node_locale: { eq: "de" } }) {
      edges {
        node {
          title
          slug
          category
          publishDate(formatString: "MMMM Do, YYYY")
          titleImage {
            gatsbyImageData(layout: CONSTRAINED, resizingBehavior: FILL)
          }
        }
      }
    }

    allFile(
      filter: {
        ext: { regex: "/(jpg)|(png)|(jpeg)/" }
        name: { in: ["blogheader"] }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
